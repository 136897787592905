import moment from 'moment'
const csvDataHelper = (x, y) => {
    const data = x
    let summary = []
    let eventName = data.eventName
    let summaryTransactions = data.totalTransactions
    let summaryTotalPos = data.totalPOS
    const summaryTotal = data.totalEventAmount
    if (eventName === '') eventName = '---'
    const produtosHandler =  (entrada, total) => {
      summary.push({ a: 'PRODUTOS', b: '', c: '', d: '', e: total, });
      summary.push({ a: 'NOME', b: '', c: 'TOTAL', d: 'COUNT', e: 'PORCENTAGEM', });
      entrada.forEach(element => {
        summary.push({
          a: element.name, c: element.totalAmount, b: '', d: element.totalCount, e: element.percent,
        });
      })
    }
    const relatorioHandler =  (entrada, modelo, total) => {
    summary.push({
      a: modelo, b: '', c: '', d: 'TOTAL', e: total,
    });
    entrada.forEach(element => {
      summary.push({
        a: element.id, c: element.totalAmount, b: '', d: element.count, e: element.percent,
      });
    })
    summary.push({ a: '', b: '', c: '', d: '', e: '', });
    }
    // CABEÇALHO
    summary.push({
      a: 'Resumo',
      b: 'de Vendas',
      c: 'Gerado em:',
      d: moment().format('DD/MM/YY'),
      e: moment().format('LT'),
    });
    summary.push({
      a: 'Nome:',
      b: eventName,
      c: '',
      d: 'Total:',
      e: summaryTotal,
    });
    summary.push({
      a: 'Transações:',
      b: summaryTransactions,
      c: '',
      d: 'Pontos de Venda:',
      e: summaryTotalPos,
    });
    // EM BRANCO
    summary.push({ a: '', b: '', c: '', d: '', e: '', });
    
    // RESUMO DE VENDAS E PRODUTOS
    summary.push({
      a: 'PAGAMENTO', c: 'TOTAL', b: '', d: 'OPERAÇÕES', e: 'PORCENTAGEM',
    });
    if(true) relatorioHandler(data.paymentIntegrado, 'RESUMO TOTAL', data.totalEventAmount)
    if(true) relatorioHandler(data.paymentIntegrado, 'INTEGRADO', data.totalIntegrado)
    if(true) relatorioHandler(data.paymentExterno, 'EXTERNO', data.totalExterno)
    if(true) produtosHandler(data.productSummary)
    return summary;
}

export default csvDataHelper;