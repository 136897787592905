import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  ButtonToolbar,
  Modal
} from 'react-bootstrap'

import Dashboard from '../dashboard'
import useMount from '../../helpers/useMount'
import Filters from '../filters'
import toParams from '../filters/toParams'

import {
  set,
  get,
  clone,
  clearEvent,
  setShowCloneEvent
} from './actions'

import Button from '../../components/button'
import Table from '../../components/table'

import Form from 'react-nonconformist'

function useStateAndDispatch () {
  const dispatch = useDispatch()
  const events = useSelector(state => state.events.events)
  const isLoading = useSelector(state => state.isLoading[get])
  const showCloneEvent = useSelector(state => state.events.showCloneEvent)

  return {
    events,
    isLoading,
    showCloneEvent,
    set: params => dispatch(set(params)),
    get: params => dispatch(get(params)),
    clone: params => dispatch(clone(params)),
    clearEvent: () => dispatch(clearEvent()),
    setShowCloneEvent: params => dispatch(setShowCloneEvent(params))
  }
}

export default function Events ({ history }) {
  const {
    set,
    get,
    clone,
    events,
    clearEvent,
    isLoading,
    showCloneEvent,
    setShowCloneEvent
  } = useStateAndDispatch()

  // if events.data has => sort array by date
  if (events.data[0]) {
    const eventsObjectKeys = Object.keys(events.data[0])
    events.data.sort((x, y) => {
      return new Date(y[eventsObjectKeys[3]]).valueOf() - new Date(x[eventsObjectKeys[3]]).valueOf()
    })
  }

  const handleClone = (id, eventName) => setShowCloneEvent({ show: true, id, eventName })
  const handleClose = () => setShowCloneEvent({ show: false })

  useMount(() => {
    get()
    clearEvent()
  })

  const filter = params => {
    get(toParams(params))
  }

  const columns = [
    { dataIndex: 'name', key: 'name', title: 'Nome' },
    { dataIndex: 'address', key: 'address', title: 'Onde' },
    { dataIndex: 'startDate', key: 'startDate', title: 'Data' },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Ações',
      render: (history, { _id, name }) => (
        <React.Fragment
        >
          <div
          >
            <Button
              to={`/events/${_id}/menus`}
              style={{
                margin: '5px',
                padding: '3px',
                fontWeight: '200'
              }}
              variant='primary'
            >
              Cardápios
            </Button>
            <Button
              to={`/events/${_id}/pos`}
              variant='primary'
              style={{
                margin: '5px',
                padding: '3px',
                fontWeight: '200'
              }}
            >
              PDVs
            </Button>
            <Button
              to={`/events/${_id}`}
              style={{
                margin: '5px',
                padding: '3px',
                fontWeight: '200'
              }}
              variant='success'
            >
              Editar
            </Button>
            <Button
              onClick={() => handleClone(_id, name)}
              style={{
                margin: '5px',
                padding: '3px',
                fontWeight: '200'
              }}
              variant='success'
            >
              Clonar
            </Button>
          </div>
        </React.Fragment>
      )
    }
  ]

  const submit = async () => {
    const { id } = showCloneEvent
    await clone({ id })
    handleClose()
    // history.push('/home')
    window.location.reload(false)
  }

  return (
    <Dashboard
      title='Eventos'
      header={
        <ButtonToolbar
          className='justify-content-end'
        >
          <Button icon='fat-add' to='/events/create'>
            Criar Novo
          </Button>
        </ButtonToolbar>
      }
    >
      <Filters
        title='Eventos'
        history={history}
        isLoading={isLoading}
        onFilter={filter}
        filters={[
          { name: 'events.name', input: 'TextInput', label: 'Nome do evento' }
        ]}
        style={{
          backgroundColor: 'red'
        }}
      >
        <Table
          columns={columns}
          data={events && events.data && events.data.length > 0 && events.data.map(d => ({
            ...d,
            actions: history
          }))}
          pagination={{
            currentPage: events.data.current_page,
            perPage: Number(events.data.per_page),
            total: events.data.total
          }}
        />
      </Filters>
      <Modal show={showCloneEvent.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Clonar evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente clonar este evento?
          <p>
            Nome: <strong fontWeight={'bold'}>{showCloneEvent.eventName}</strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Form
            values={showCloneEvent}
            onSubmit={submit}
            onChange={set}
          >
            {(connect, submit) => (
              <form onSubmit={e => {
                e.preventDefault()
                submit()
              }}>
                <Button variant='primary' onClick={submit}>
                Clonar
                </Button>
                <Button variant='secondary' onClick={handleClose}>
                Cancelar
                </Button>
              </form>
            )}
          </Form>
        </Modal.Footer>
      </Modal>
    </Dashboard>
  )
}
