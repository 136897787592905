import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import uuid from "uuid";

import Card from "../../components/card";
import Dashboard from "../dashboard";
import Filters from "../filters";

import { getSalesSummaryByPOS } from "./actions";
import csvDataHelper from "./csvHelper";

function useStateAndDispatch() {
  const dispatch = useDispatch();
  const salesSummary = useSelector(
    (state) => state.salesSummaryByPOS.salesSummary
  );
  const isLoadingSalesSummary = useSelector(
    (state) => state.isLoading[getSalesSummaryByPOS]
  );

  return {
    salesSummary,
    isLoadingSalesSummary,
    getSalesSummaryByPOS: (params) => dispatch(getSalesSummaryByPOS(params)),
  };
}

export default function Sales({ history, match }) {
  let hideMenu = false;
  let data = [];
  if (match.url.includes("/external")) {
    hideMenu = true;
  }

  const { salesSummary, getSalesSummaryByPOS, isLoadingSalesSummary } =
    useStateAndDispatch();

  const filter = (filter) => {
    const startAt = filter.StartAt || "01/01/2019";
    const startAtHour = filter.StartAtHour || "00:00";
    const endAt = filter.EndAt || "01/01/2099";
    const endAtHour = filter.EndAtHour || "00:00";

    const mapper = {
      uuid: filter.Event,
      startAt: startAt + " " + startAtHour,
      endAt: endAt + " " + endAtHour,
    };

    getSalesSummaryByPOS(mapper);
  };

  if (!isEmpty(salesSummary)) data = csvDataHelper(salesSummary);

  return (
    <Dashboard
      title={"Vendas por PDV"}
      hideMenu={hideMenu}
      header={
        <Filters
          isLoading={isLoadingSalesSummary}
          history={history}
          onFilter={filter}
          filters={[
            { name: "Event", input: "Event", idEvent: match.params.uuid },
            { name: "Empty" },
            { name: "StartAt", input: "Date", label: "Início" },
            { name: "StartAtHour", input: "Hour", label: "Horário" },
            { name: "EndAt", input: "Date", label: "Fim" },
            { name: "EndAtHour", input: "Hour", label: "Horário" },
          ]}
          title={salesSummary.eventName || "Resumo de vendas"}
        />
      }
    >
      <Row
        style={{
          marginTop: 50,
          display: isEmpty(salesSummary) ? "none" : "flex",
        }}
      >
        <Col lg="4" xl="4">
          <Card className="card-stats mb-4 mb-xl-0">
            <Row>
              <div className="col">
                <h5 className="text-uppercase text-muted mb-0">
                  Total vendido
                </h5>
                <span className="h2 font-weight-bold mb-0">
                  {salesSummary.total}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                  <i className="fas fa-dollar-sign" />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg="4" xl="4">
          <Card className="card-stats mb-4 mb-xl-0">
            <Row>
              <div className="col">
                <h5 className="text-uppercase text-muted mb-0">
                  Total de pontos de venda
                </h5>
                <span className="h2 font-weight-bold mb-0">
                  {salesSummary.totalPOS}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                  <i className="fas fa-store" />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg="6" xl="4">
          <Card className="card-stats mb-4 mb-xl-0">
            <Row>
              <div className="col">
                <CSVLink
                  className="btn btn-primary success"
                  data={data}
                  filename={`VENDAS_POR_PDV_${moment().format("DD/MM/YYYY_HH:mm:ss")}`}
                >
                  Exportar
                </CSVLink>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {isEmpty(salesSummary) ? (
        ""
      ) : (
        <div>
          <Row>
            <Col sm={12} style={{ marginTop: 30 }}>
              <Card
                isLoading={isLoadingSalesSummary}
                shadow
                header={
                  <h3 className="mb-0">Vendas por PDV e método de pagamento</h3>
                }
              >
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ display: isEmpty(salesSummary) ? "none" : "table" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">PDV</th>
                      <th scope="col">Terminal</th>
                      <th scope="col">CPF</th>
                      <th scope="col">Método de Pagamento</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(salesSummary) &&
                      salesSummary.summary.map((data) => (
                        <tr key={uuid()}>
                          <th scope="row">{data.posName}</th>
                          <td>{data.terminalCode}</td>
                          <td>{data.cpf}</td>
                          <td>{data.paymentMethod}</td>
                          <td>
                            R$ {parseFloat(data.amount).toLocaleString("pt-BR")}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{salesSummary.total}</td>
                    </tr>
                  </tfoot>
                </Table>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Dashboard>
  );
}
