import moment from "moment";
const csvDataHelper = (x, y) => {
  const data = x;
  let summary = [];

  // CABEÇALHO
  summary.push({
    a: "PDV",
    b: "TERMINAL",
    c: "CPF",
    d: "MÉTODO DE PAGAMENTO",
    e: "TOTAL",
  });

  data.summary.forEach((d) => {
    summary.push({
      a: d.posName,
      b: d.terminalCode,
      c: d.cpf,
      d: d.paymentMethod,
      e: d.amount.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    });
  });

  // EM BRANCO
  summary.push({ a: "", b: "", c: "", d: "", e: "" });

  summary.push({ a: "TOTAL", b: "", c: "", d: "", e: data.total });

  // EM BRANCO
  summary.push({ a: "", b: "", c: "", d: "", e: "" });

  // RODAPÉ
  summary.push({
    a: `Resumo de Vendas por PDV gerando em: ${moment().format(
      "DD/MM/YYYY HH:mm:ss"
    )}`,
  });

  return summary;
};

export default csvDataHelper;
