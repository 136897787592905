import React from 'react'
import { CSVLink } from 'react-csv'


import { Link } from 'react-router-dom'

import { Row, Col, Table, ProgressBar } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import useMount from '../../helpers/useMount'
import Card from '../../components/card'
import Dashboard from '../dashboard'
import Filters from '../filters'

import { getSalesSummary, getSalesSummaryExternal } from './actions'
import csvDataHelper from './csvHelper'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'


function useStateAndDispatch () {
  const dispatch = useDispatch()
  const salesSummary = useSelector(state => state.salesSummary.salesSummary)
  const isLoadingSalesSummary = useSelector(state => state.isLoading[getSalesSummary])

  return {
    salesSummary,
    isLoadingSalesSummary,
    getSalesSummary: params => dispatch(getSalesSummary(params)),
    getSalesSummaryExternal: params => dispatch(getSalesSummaryExternal(params))
    // clearMenu: () => dispatch(clearMenu())
  }
}

export default function Sales ({ history, match }) {
  let hideMenu = false
  let data = []
  if (match.url.includes('/external')) {
    hideMenu = true
  }

  const {
    salesSummary,
    getSalesSummary,
    getSalesSummaryExternal,
    isLoadingSalesSummary
  } = useStateAndDispatch()

  const filter = filter => {
    const startAt = filter.StartAt || '01/01/2019'
    const startAtHour = filter.StartAtHour || '00:00'
    const endAt = filter.EndAt || '01/01/2099'
    const endAtHour = filter.EndAtHour || '00:00'

    const mapper = {
      uuid: (filter.CashierCPF && filter.CashierCPF.event) || match.params.uuid,
      cpf: (filter.CashierCPF && filter.CashierCPF.cashier) || '',
      terminalCode: (filter.CashierCPF && filter.CashierCPF.pos) || '',
      startAt: startAt + ' ' + startAtHour,
      endAt: endAt + ' ' + endAtHour
    }

    getSalesSummary(mapper)
  }

  useMount(() => {
    if (match.url.includes('/external')) {
      getSalesSummaryExternal({ uuid: match.params.uuid })
    }
  })
  if (!isEmpty(salesSummary)) data = csvDataHelper(salesSummary)
  return (
    <Dashboard
      title={salesSummary.eventName || 'Resumo de vendas'}
      hideMenu={hideMenu}
      header={
        <Filters
          isLoading={isLoadingSalesSummary}
          history={history}
          onFilter={filter}
          filters={[
            { name: 'CashierCPF', input: 'CashierCPF', label: 'CPF do Caixa', hideEvent: hideMenu, idEvent: match.params.uuid },
            { name: 'StartAt', input: 'Date', label: 'Início' },
            { name: 'StartAtHour', input: 'Hour', label: 'Horário' },
            { name: 'EndAt', input: 'Date', label: 'Fim' },
            { name: 'EndAtHour', input: 'Hour', label: 'Horário' }
          ]}
          title={salesSummary.eventName || 'Resumo de vendas'}
        />
      }
    >
      {
        !hideMenu &&
        <Row style={{ marginTop: 50, display: isEmpty(salesSummary) ? 'none' : 'flex' }}>
          <Col lg='6' xl='6'>
            <Card className='card-stats mb-4 mb-xl-0'>
              <Row>
                <div className='col'>
                  <h5 className='text-uppercase text-muted mb-0'>
                    Link Externo
                  </h5>
                  <span className='h2 font-weight-bold mb-0'>
                    <Link to={`/external/reports/sales-summary/${salesSummary.eventId}`} target='_blank' className='text-body'>
                      <small><i className='fas fa-sign-out-alt' /> Clique aqui para ir ao link externo do evento</small>
                    </Link>
                  </span>
                </div>
                <Col className='col-auto'>
                  <div className='icon icon-shape bg-success text-white rounded-circle shadow'>
                    <i className='fas fa-sign-out-alt' />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg='6' xl='6'>
            <Card className='card-stats mb-4 mb-xl-0'>
              <Row>
                <div className='col'>
                  <h5 className='text-uppercase text-muted mb-0'>
                    Exportar Relatório
                  </h5>
                  <span className='h2 font-weight-bold mb-0'>
                    <Link to={`/external/reports/sales-summary/${salesSummary.eventId}`} target='_blank' className='text-body'>

                    </Link>
                  </span>
                </div>
                <Col className='col-auto'>
                  <div 
                  >
                    <CSVLink 
                      className="btn btn-primary success"
                      data={data} 
                      filename={moment().format('DD/MM/YY LT')}
                      >
                        Exportar
                        </CSVLink>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      }
      <Row style={{ marginTop: 50, display: isEmpty(salesSummary) ? 'none' : 'flex' }}>
        <Col lg='6' xl='4'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <Row>
              <div className='col'>
                <h5 className='text-uppercase text-muted mb-0'>
                  Total vendido
                </h5>
                <span className='h2 font-weight-bold mb-0'>
                  R$ {parseFloat(salesSummary.totalEventAmount).toLocaleString('pt-BR')}
                </span>
              </div>
              <Col className='col-auto'>
                <div className='icon icon-shape bg-success text-white rounded-circle shadow'>
                  <i className='fas fa-dollar-sign' />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg='6' xl='4'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <Row>
              <div className='col'>
                <h5 className='text-uppercase text-muted mb-0'>
                    Total transações
                </h5>
                <span className='h2 font-weight-bold mb-0'>
                  {salesSummary.totalTransactions}
                </span>
              </div>
              <Col className='col-auto'>
                <div className='icon icon-shape bg-info text-white rounded-circle shadow'>
                  <i className='fas fa-chart-bar' />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg='6' xl='4'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <Row>
              <div className='col'>
                <h5 className='text-uppercase text-muted mb-0'>
                    Total de pontos de venda
                </h5>
                <span className='h2 font-weight-bold mb-0'>
                  {salesSummary.totalPOS}
                </span>
              </div>
              <Col className='col-auto'>
                <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                  <i className='fas fa-store' />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {isEmpty(salesSummary) ? '' : <div>
      <Row>
        <Col sm={12} style={{ marginTop: 30 }}>
          
          <Card isLoading={isLoadingSalesSummary} shadow header={<h3 className='mb-0'>Estatísticas de pagamentos</h3>}>
            <Table className='align-items-center table-flush' responsive style={{ display: isEmpty(salesSummary) ? 'none' : 'table' }}>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Formas de pagamento</th>
                  <th scope='col'>Valor total</th>
                  <th scope='col'>Total operações</th>
                  <th scope='col'>Porcentagem total das vendas</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(salesSummary) && salesSummary.paymentMethod.map(paymentMethod => (
                  <tr key={paymentMethod.id}>
                    <th scope='row'>{paymentMethod.paymentMethod}</th>
                    <td>R$ {parseFloat(paymentMethod.totalAmount).toLocaleString('pt-BR')}</td>
                    <td>{paymentMethod.count}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <span className='mr-2'>{paymentMethod.percent}%</span>
                        <div>
                          <ProgressBar
                            max='100'
                            now={paymentMethod.percent}
                            variant='success'
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          {/* </Card> */}
        {/* </Col> */}
        {/* <Col sm={12} style={{ marginTop: 30 }}> */}
          {/* <Card isLoading={isLoadingSalesSummary} shadow header={<h4 className='mb-0'>PDVs Externos</h4>}> */}
            <br/>
            <h4 className='mb-0'>Integrados  - Total: R$ {parseFloat(salesSummary.totalIntegrado).toLocaleString('pt-BR')}</h4>
            <br/>
            <Table className='align-items-center table-flush' responsive style={{ display: isEmpty(salesSummary) ? 'none' : 'table' }}>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Formas de pagamento</th>
                  <th scope='col'>Valor total</th>
                  <th scope='col'>Total operações</th>
                  <th scope='col'>Porcentagem total das vendas</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(salesSummary) && salesSummary.paymentIntegrado.map(paymentMethod => (
                  <tr key={paymentMethod.id}>
                    <th scope='row'>{paymentMethod.paymentMethod}</th>
                    <td>R$ {parseFloat(paymentMethod.totalAmount).toLocaleString('pt-BR')}</td>
                    <td>{paymentMethod.count}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <span className='mr-2'>{paymentMethod.percent}%</span>
                        <div>
                          <ProgressBar
                            max='100'
                            now={paymentMethod.percent}
                            variant='success'
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <br/>
            <h4 className='mb-0'>Externos  - Total: R$ {parseFloat(salesSummary.totalExterno).toLocaleString('pt-BR')}</h4>
            <br/>
            <Table className='align-items-center table-flush' responsive style={{ display: isEmpty(salesSummary) ? 'none' : 'table' }}>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Formas de pagamento</th>
                  <th scope='col'>Valor total</th>
                  <th scope='col'>Total operações</th>
                  <th scope='col'>Porcentagem total das vendas</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(salesSummary) && salesSummary.paymentExterno.map(paymentMethod => (
                  <tr key={paymentMethod.id}>
                    <th scope='row'>{paymentMethod.paymentMethod}</th>
                    <td>R$ {parseFloat(paymentMethod.totalAmount).toLocaleString('pt-BR')}</td>
                    <td>{paymentMethod.count}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <span className='mr-2'>{paymentMethod.percent}%</span>
                        <div>
                          <ProgressBar
                            max='100'
                            now={paymentMethod.percent}
                            variant='success'
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col sm={12} style={{ marginTop: 30 }}>
          <Card isLoading={isLoadingSalesSummary} shadow header={<h3 className='mb-0'>Resumo de produtos</h3>}>
            <Table className='align-items-center table-flush' responsive style={{ display: isEmpty(salesSummary) ? 'none' : 'table' }}>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Produto</th>
                  <th scope='col'>Quantidade</th>
                  <th scope='col'>Valor total</th>
                  <th scope='col'>Representação do valor total vendido</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(salesSummary) && salesSummary.productSummary.map(product => (
                  <tr key={product.id}>
                    <th scope='row'>{product.name}</th>
                    <td>{product.totalCount}</td>
                    <td>R$ {parseFloat(product.totalAmount).toLocaleString('pt-BR')}</td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <span className='mr-2'>{product.percent}%</span>
                        <div>
                          <ProgressBar
                            max='100'
                            now={product.percent}
                            variant='success'
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>{salesSummary.totalProducts}</td>
                  <td>R$ {parseFloat(salesSummary.totalEventAmount).toLocaleString('pt-BR')}</td>
                  <td> </td>
                </tr>
              </tfoot>
            </Table>
          </Card>
        </Col>
      </Row>
      </div>
      }
    </Dashboard>
  )
}
