import { createReducer } from 'redux-act'
import get from 'lodash/get';
import { fulfilled, rejected } from '../../helpers/reducerPromiseHelper'

import {
  getSalesAudit
} from './actions'

const initialState = {
  data: {}
}

export default createReducer({
  [fulfilled(getSalesAudit)]: (state, payload) => ({
    ...state,
    data: get(payload, ['data', 'data'], {}),
  }),

  [rejected(getSalesAudit)]: (state, payload) => ({
    ...state,
    data: { ...initialState.data }
  }),
}, { ...initialState })
