import React, { useState } from 'react'
import { Row, Col, Table, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
import moment from 'moment'
import { Link } from 'react-router-dom'

import Card from '../../components/card'
import Button from '../../components/button'
import Dashboard from '../dashboard'
import Filters from '../filters'

import { getOrdersDelivered } from './actions'

function useStateAndDispatch () {
  const dispatch = useDispatch()
  const ordersDelivered = useSelector(state => state.ordersDelivered.ordersDelivered)
  const isLoadingOrdersDelivered = useSelector(state => state.isLoading[getOrdersDelivered])

  return {
    ordersDelivered,
    isLoadingOrdersDelivered,
    getOrdersDelivered: params => dispatch(getOrdersDelivered(params))
  }
}

function replaceCPF (cpf) {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export default function OrdersDelivered ({ history, match }) {
  const [showModal, setShowModal] = useState({})

  const {
    ordersDelivered,
    getOrdersDelivered,
    isLoadingOrdersDelivered
  } = useStateAndDispatch()

  const filter = filter => {
    const mapper = {
      uuid: filter.Event
    }

    getOrdersDelivered(mapper)
  }

  const handleClose = () => setShowModal({})

  return (
    <Dashboard
      title='Resumo de pedidos entregues'
      header={
        <Filters
          isLoading={isLoadingOrdersDelivered}
          history={history}
          onFilter={filter}
          filters={[
            { name: 'Event' }
          ]}
          title='Resumo de vendas'
        />
      }
    >
      <Row style={{ marginTop: 50, display: isEmpty(ordersDelivered) ? 'none' : 'flex' }}>
        <Col lg='6' xl='4'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <Row>
              <div className='col'>
                <h5 className='text-uppercase text-muted mb-0'>
                  Total pedidos entregues
                </h5>
                <span className='h2 font-weight-bold mb-0'>
                  {ordersDelivered.successfulDeliveries}
                </span>
              </div>
              <Col className='col-auto'>
                <div className='icon icon-shape bg-success text-white rounded-circle shadow'>
                  <i className='fas fa-check' />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg='6' xl='4'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <Row>
              <div className='col'>
                <h5 className='text-uppercase text-muted mb-0'>
                  Total de tentativas de leituras com falhas
                </h5>
                <span className='h2 font-weight-bold mb-0'>
                  {ordersDelivered.errorDeliveries}
                </span>
              </div>
              <Col className='col-auto'>
                <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                  <i className='fas fa-exclamation-circle' />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg='6' xl='4'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <Row>
              <div className='col'>
                <h5 className='text-uppercase text-muted mb-0'>
                  Total entregadores
                </h5>
                <span className='h2 font-weight-bold mb-0'>
                  {ordersDelivered.totalDeliverers}
                </span>
              </div>
              <Col className='col-auto'>
                <div className='icon icon-shape bg-info text-white rounded-circle shadow'>
                  <i className='fas fa-user-friends' />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} style={{ marginTop: 30 }}>
          <Card isLoading={isLoadingOrdersDelivered} shadow header={<h3 className='mb-0'>Resumo de produtos entregues</h3>}>
            <Table className='align-items-center table-flush' responsive style={{ display: isEmpty(ordersDelivered) ? 'none' : 'table' }}>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Código</th>
                  <th scope='col'>Produto</th>
                  <th scope='col'>Liberado por</th>
                  <th scope='col'>Data</th>
                  <th scope='col'>Tentativa de fraude</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(ordersDelivered) && ordersDelivered.ordersDelivered.map(product => {
                  const readingAttempts = get(product, 'readingAttempts', [])
                  const deliveryId = readingAttempts.indexOf(true)
                  const cpf = replaceCPF(get(product, ['cpf', deliveryId], ''))
                  const date = moment.utc(get(product, ['createdAt', deliveryId], '')).format('DD/MM/YYYY HH:mm:ss')
                  const hasFraud = readingAttempts.length > 1

                  const key = `${product.productIndex || 0}-${product.transactionId}`

                  return (
                    <tr key={key} style={{ background: showModal[key] ? '#f6f9fc' : null }}>
                      <th scope='row'>{key}</th>
                      <th scope='row'>{product.productName}</th>
                      <th scope='row'>{cpf}</th>
                      <th scope='row'>{date}</th>
                      <th scope='row'>
                        {hasFraud
                          ? <Link to='#' onClick={() =>
                            showModal[key]
                              ? setShowModal(set(showModal, key, false))
                              : setShowModal(set(showModal, key, true))}>
                            { !showModal[key] ? 'Mais Detalhes' : 'Menos Detalhes' }
                          </Link> : 'Não detectado'}
                      </th>
                      <Modal show={showModal[key]} onHide={handleClose} size='lg' centered>
                        <Modal.Header closeButton>
                          <Modal.Title>Tentativas de leitura</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <h5>Código: {key}</h5>
                          <br/>
                          <Table responsive>
                            <thead className='thead-light'>
                              <tr>
                                <th scope='col'>Usuário</th>
                                <th scope='col'>Data da leitura</th>
                                <th scope='col'>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {product.readingAttempts.map((readingAttempt, i) => {
                                if (readingAttempt) return null
                                return (
                                  <tr style={{ marginLeft: 23 }}>
                                    <th>{replaceCPF(get(product, ['cpf', i], ''))}</th>
                                    <th>{moment.utc(get(product, ['createdAt', i], '')).format('DD/MM/YYYY HH:mm:ss')}</th>
                                    <th>{readingAttempt ? 'Sucesso' : 'Falha'}</th>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant='success' onClick={handleClose}>
                            Fechar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Dashboard>
  )
}